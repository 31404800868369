.rio-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: max-content;
    min-width: 1em;
    height: 1em;
    flex: 0 0 auto;
    font-size: var(--rio-badge-size);
}

.rio-badge--is_filled.rio-badge--shape_circle {
    background-color: var(--rio-badge-circle-color);
}

.rio-badge--is_stroke.rio-badge--shape_circle {
    background-color: var(--color_background_neutral_silent);
    border: max(1.5px, 0.0725em) solid var(--rio-badge-circle-color);
}

.rio-badge--shape_circle {
    border-radius: 1000px;
}

.rio-badge__shape {
    position: absolute;
    inset: -12%;
}

.rio-badge__content {
    display: flex;
    font-weight: 500;
    font-size: 0.75em;
    color: var(--rio-badge-content-color);
    transform: translateY(var(--rio-badge-content-translate-y));
}

.rio-badge__content--with_icon-and-children {
    padding-inline: 0.125em 0.25em;
}

.rio-badge__children--no_icon {
    padding-inline: 0.175em;
}