.tabs {
    position: relative;
    overflow: hidden;
}

.tabs--content-padding-variant_none {
    --tabs-content-padding: 0;
}

.tabs--content-padding-variant_small {
    --tabs-content-padding: 8px;
}

.tabs--content-padding-variant_medium {
    --tabs-content-padding: 16px;
}

.tabs--content-padding-variant_large {
    --tabs-content-padding: 24px;
}

.tabs:not(:last-child) {
    margin-bottom: 20px;
}

.tabs-labels {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    white-space: nowrap;
    height: 32px;
    background: var(--color_background_neutral_silent);
    border-block-end: 1px solid var(--color_border_neutral);

    --tabs-label-display: flex;
}

.tabs-labels--no_labels {
    --tabs-label-display: none;
}

.tabs-labels--is_shadow {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    inset-block-start: 0;
}

.tabs-content {
    display: none;
    padding-top: var(--tabs-content-padding);
}

.tabs-content--active {
    display: block;
}

.tabs-labels-tab {
    display: flex;
    position: relative;
    flex: 0 0 auto;
    align-items: center;
    gap: 4px;
    height: 100%;
    padding-inline: 12px;
    white-space: nowrap;
    border-start-start-radius: 4px;
    border-start-end-radius: 4px;
    background: var(--color_background_transparent);
    border: none;
    color: var(--color_text_neutral_quiet);
    cursor: pointer;
    margin-top: 1px;
    border-block-end: 2px solid var(--color_border_transparent);
    transition: background var(--animation_duration_fast), border var(--animation_duration_fast);
}

.tabs-labels-tab::-moz-focus-inner {
    border: 0;
}

.tabs-labels-tab::after {
    inset-inline: 2px;
}

.tabs-labels-tab-icon {
    position: relative;
    display: block;
    font-weight: 500;
    flex: 0 0 auto;
    width: 16px;
    font-size: 16px;
    text-align: center;
}

.tabs-labels-tab-label {
    position: relative;
    display: var(--tabs-label-display);
    align-items: center;
    font-weight: 500;
    flex: 1 0 auto;
    min-width: 16px;
    font-size: 12px;
    border-inline-start-width: 1px;
    border-block-start-width: 1px;
    color: var(--color_text_neutral);
    transition: color var(--animation_duration_fast);
}

.tabs-labels-tab-label--no_icon {
    display: flex !important;
}

.tabs-labels-tab-label > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tabs-labels-tab:not(.tabs-labels-tab--active):not(.tabs-labels-tab--disabled):hover {
    border-block-end-color: var(--color_border_interactive_whisper_hover);
    background: var(--color_background_interactive_whisper_hover);
    transition: border var(--animation_duration_fast);
}

.tabs-labels-tab:not(.tabs-labels-tab--active):not(.tabs-labels-tab--disabled):hover > .tabs-labels-tab-label {
    color: var(--color_text_interactive_hover);
    transition: none;
}

.tabs-labels-tab:not(.tabs-labels-tab--active):not(.tabs-labels-tab--disabled):active {
    border-block-end-color: var(--color_border_interactive_whisper_active);
    background: var(--color_background_interactive_whisper_active);
    transition: border var(--animation_duration_fast);
}

.tabs-labels-tab:not(.tabs-labels-tab--active):not(.tabs-labels-tab--disabled):active > .tabs-labels-tab-label {
    color: var(--color_text_interactive_active);
    transition: none;
}

.tabs-labels-tab--active {
    border-block-end-color: var(--color_border_interactive_selected);
    cursor: default;
}

.tabs-labels-tab--active > .tabs-labels-tab-label {
    color: var(--color_text_interactive_selected);
}

.tabs-labels-tab--disabled {
    cursor: not-allowed;
}

.tabs-labels-tab--disabled > .tabs-labels-tab-icon,
.tabs-labels-tab--disabled > .tabs-labels-tab-label {
    color: var(--color_text_interactive_disabled);
}

.tabs-labels-tab--disabled.tabs-labels-tab--active {
    border-block-end-color: var(--color_border_interactive_disabled);
}