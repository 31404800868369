@font-face {
	font-family: "rio-icons";
	font-display: block;
	src: url("/asset/rio-icons-26d01c75c50682e5581a.woff2") format("woff2"),
url("/asset/rio-icons-6bafc2fe11a0726cd50a.ttf") format("truetype");
}

.rio-icon {
	line-height: 1;
}

.rio-icon:before {
	font-family: rio-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.rio-icon--icon_arrow-circle-clockwise:before {
	content: "\f101";
}
.rio-icon--icon_arrow-down:before {
	content: "\f102";
}
.rio-icon--icon_arrow-left:before {
	content: "\f103";
}
.rio-icon--icon_arrow-right:before {
	content: "\f104";
}
.rio-icon--icon_arrow-to-star:before {
	content: "\f105";
}
.rio-icon--icon_arrow-up:before {
	content: "\f106";
}
.rio-icon--icon_arrowhead-right:before {
	content: "\f107";
}
.rio-icon--icon_bot-dots--with-two-circling-arrows:before {
	content: "\f108";
}
.rio-icon--icon_bot-dots:before {
	content: "\f109";
}
.rio-icon--icon_calendar:before {
	content: "\f10a";
}
.rio-icon--icon_checkmark:before {
	content: "\f10b";
}
.rio-icon--icon_chevron-down:before {
	content: "\f10c";
}
.rio-icon--icon_chevron-left:before {
	content: "\f10d";
}
.rio-icon--icon_chevron-right:before {
	content: "\f10e";
}
.rio-icon--icon_chevron-up:before {
	content: "\f10f";
}
.rio-icon--icon_circle--half-dottted-stroke-only:before {
	content: "\f110";
}
.rio-icon--icon_circle--stroke-only:before {
	content: "\f111";
}
.rio-icon--icon_circle:before {
	content: "\f112";
}
.rio-icon--icon_clipart-painting:before {
	content: "\f113";
}
.rio-icon--icon_clock-arrow-reversed:before {
	content: "\f114";
}
.rio-icon--icon_clock:before {
	content: "\f115";
}
.rio-icon--icon_close-x:before {
	content: "\f116";
}
.rio-icon--icon_cloud--star:before {
	content: "\f117";
}
.rio-icon--icon_cloud:before {
	content: "\f118";
}
.rio-icon--icon_computer-laptop--filled:before {
	content: "\f119";
}
.rio-icon--icon_curly-braces:before {
	content: "\f11a";
}
.rio-icon--icon_debug-arrow-step-in:before {
	content: "\f11b";
}
.rio-icon--icon_debug-arrow-step-out:before {
	content: "\f11c";
}
.rio-icon--icon_debug-arrow-step-over:before {
	content: "\f11d";
}
.rio-icon--icon_document--checked:before {
	content: "\f11e";
}
.rio-icon--icon_document--lined:before {
	content: "\f11f";
}
.rio-icon--icon_document-checkmark:before {
	content: "\f120";
}
.rio-icon--icon_document:before {
	content: "\f121";
}
.rio-icon--icon_documentation:before {
	content: "\f122";
}
.rio-icon--icon_exclamation-mark--internal-use:before {
	content: "\f123";
}
.rio-icon--icon_exclamation-mark:before {
	content: "\f124";
}
.rio-icon--icon_export:before {
	content: "\f125";
}
.rio-icon--icon_eye--line-through:before {
	content: "\f126";
}
.rio-icon--icon_eye:before {
	content: "\f127";
}
.rio-icon--icon_file:before {
	content: "\f128";
}
.rio-icon--icon_flow-shapes:before {
	content: "\f129";
}
.rio-icon--icon_flow-structure--dot:before {
	content: "\f12a";
}
.rio-icon--icon_flow-structure--reversed:before {
	content: "\f12b";
}
.rio-icon--icon_flow-structure:before {
	content: "\f12c";
}
.rio-icon--icon_folder--filled-open:before {
	content: "\f12d";
}
.rio-icon--icon_folder--filled:before {
	content: "\f12e";
}
.rio-icon--icon_folder--open:before {
	content: "\f12f";
}
.rio-icon--icon_folder-filled--open:before {
	content: "\f130";
}
.rio-icon--icon_folder-filled:before {
	content: "\f131";
}
.rio-icon--icon_folder-git--filled-open:before {
	content: "\f132";
}
.rio-icon--icon_folder-git--filled:before {
	content: "\f133";
}
.rio-icon--icon_folder-git--open:before {
	content: "\f134";
}
.rio-icon--icon_folder-git:before {
	content: "\f135";
}
.rio-icon--icon_folder-plus:before {
	content: "\f136";
}
.rio-icon--icon_folder:before {
	content: "\f137";
}
.rio-icon--icon_gavel--cursor:before {
	content: "\f138";
}
.rio-icon--icon_gavel:before {
	content: "\f139";
}
.rio-icon--icon_gear-cog:before {
	content: "\f13a";
}
.rio-icon--icon_globe--skeleton:before {
	content: "\f13b";
}
.rio-icon--icon_globe:before {
	content: "\f13c";
}
.rio-icon--icon_hamburger-menu:before {
	content: "\f13d";
}
.rio-icon--icon_hexagons:before {
	content: "\f13e";
}
.rio-icon--icon_import:before {
	content: "\f13f";
}
.rio-icon--icon_info-i:before {
	content: "\f140";
}
.rio-icon--icon_line-through-disk:before {
	content: "\f141";
}
.rio-icon--icon_loading-spinner:before {
	content: "\f142";
}
.rio-icon--icon_magnifier--code:before {
	content: "\f143";
}
.rio-icon--icon_magnifying-glass--code:before {
	content: "\f144";
}
.rio-icon--icon_magnifying-glass:before {
	content: "\f145";
}
.rio-icon--icon_minus--bold:before {
	content: "\f146";
}
.rio-icon--icon_package--arrowhead:before {
	content: "\f147";
}
.rio-icon--icon_package--wand:before {
	content: "\f148";
}
.rio-icon--icon_pause-rectangles:before {
	content: "\f149";
}
.rio-icon--icon_pencil:before {
	content: "\f14a";
}
.rio-icon--icon_pin--filled-line-through:before {
	content: "\f14b";
}
.rio-icon--icon_pin--filled:before {
	content: "\f14c";
}
.rio-icon--icon_pin--skeleton:before {
	content: "\f14d";
}
.rio-icon--icon_play-triangle:before {
	content: "\f14e";
}
.rio-icon--icon_plus--bold:before {
	content: "\f14f";
}
.rio-icon--icon_plus:before {
	content: "\f150";
}
.rio-icon--icon_question-mark:before {
	content: "\f151";
}
.rio-icon--icon_rectangle-dash:before {
	content: "\f152";
}
.rio-icon--icon_rectangle-with-horizontal-lines-above:before {
	content: "\f153";
}
.rio-icon--icon_spacer:before {
	content: "\f154";
}
.rio-icon--icon_sparkle:before {
	content: "\f155";
}
.rio-icon--icon_speech-bubble:before {
	content: "\f156";
}
.rio-icon--icon_square-brackets:before {
	content: "\f157";
}
.rio-icon--icon_square-with-arrow-out-top-right:before {
	content: "\f158";
}
.rio-icon--icon_square:before {
	content: "\f159";
}
.rio-icon--icon_stacked-play-boxes:before {
	content: "\f15a";
}
.rio-icon--icon_three-circles--inside-circle-with-plus:before {
	content: "\f15b";
}
.rio-icon--icon_three-circles--inside-circle:before {
	content: "\f15c";
}
.rio-icon--icon_three-circles:before {
	content: "\f15d";
}
.rio-icon--icon_three-vertical-dots-meatball-menu:before {
	content: "\f15e";
}
.rio-icon--icon_torso-of-circle-and-square:before {
	content: "\f15f";
}
.rio-icon--icon_trash-can:before {
	content: "\f160";
}
.rio-icon--icon_triangle-filled-down:before {
	content: "\f161";
}
.rio-icon--icon_triangle:before {
	content: "\f162";
}
.rio-icon--icon_video-camera:before {
	content: "\f163";
}
.rio-icon--icon_warning-triangle:before {
	content: "\f164";
}
